import Top from "../components/top";
// import Video from "../components/video";
// import About from "../components/about";
// import Features from "../components/features";
// import Gallery from "../components/gallery";
// import Awards from "../components/awards";

const sections = [
  { name: "home", component: Top },
  // { name: "video", component: Video }
  // { name: "about", component: About },
  // { name: "features", component: Features },
  // { name: "gallery", component: Gallery },
  // { name: "awards", component: Awards }
];

export default sections;
