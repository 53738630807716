import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Video from "./video";
import Gallery from "./gallery";
import { GatsbyImage } from "gatsby-plugin-image";
import * as cssClass from "./top.module.scss";

const Top = ({ documentScrollPosition, isMobile, height }) => {
  const data = useStaticQuery(graphql`
    query {
      heroMobile: file(relativePath: { eq: "desta-hero-mobile.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      heroFg: file(relativePath: { eq: "desta-hero-fg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      heroBg: file(relativePath: { eq: "desta-hero-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      rocksNear: file(relativePath: { eq: "desta-hero-rocks-near.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      rocksFar: file(relativePath: { eq: "desta-hero-rocks-far.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      glows: file(relativePath: { eq: "desta-hero-glows.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      destaLogo: file(relativePath: { eq: "desta-game-logo-ustwo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      steamLogo: file(relativePath: { eq: "logo-steam.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      appStoreLogo: file(relativePath: { eq: "logo-appStore.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      googlePlayLogo: file(relativePath: { eq: "logo-googlePlay.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      nintendoLogo: file(relativePath: { eq: "logo-nintendo-switch.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      trailerPoster: file(relativePath: { eq: "desta-ustwo-trailer-poster.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      gameplayPoster: file(relativePath: { eq: "desta-ustwo-gameplay-poster.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      trailer: file(name: { eq: "desta-ustwo-trailer" }) {
        publicURL
      }
      gameplay: file(name: { eq: "desta-ustwo-gameplay" }) {
        publicURL
      }
    }
  `);
  
  const bgImageBgStyles = {
    transform: `translate3d(0, calc(0% - ${documentScrollPosition * 0.02}px), 0)`
  };

  const rocksFarStyles = {
    transform: `translate3d(0, calc(0% - ${documentScrollPosition * 0.06}px), 0)`
  };

  const rocksNearStyles = {
    transform: `translate3d(0, calc(0% - ${documentScrollPosition * 0.1}px), 0)`
  };


  const fgImageBgStyles = {
    transform: `translate3d(0, calc(130px - ${(documentScrollPosition / height) * 135}px), 0)`,
    opacity: documentScrollPosition > (height * 1.5) ? 1 - ((documentScrollPosition - (height * 1.5)) / (height * 0.5)) : 1,
  };

  const linkStyles = {
    opacity: documentScrollPosition > (height * 1.5) ? 1 - ((documentScrollPosition - (height * 1.5)) / (height * 0.5)) : 1,
  };

  const h1Styles = {
    transform: `translate3d(0, -${documentScrollPosition * 0.25}px, 0)`
  };

  const gameLinks = (
    <>
      <a href="https://apps.apple.com/us/app/desta-the-memories-between/id1599584290" className={`${cssClass.link}`}>
        <GatsbyImage image={data.appStoreLogo.childImageSharp.gatsbyImageData} alt="App Store link" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.netflix.NGP.ProjectDesta" className={`${cssClass.link}`}>
        <GatsbyImage image={data.googlePlayLogo.childImageSharp.gatsbyImageData} alt="Google Play link" />
      </a>
      <a href="https://store.steampowered.com/app/2009650/Desta_The_Memories_Between/" className={`${cssClass.link}`}>
        <GatsbyImage image={data.steamLogo.childImageSharp.gatsbyImageData} alt="Steam link" />
      </a>
      <a href="https://www.nintendo.co.uk/Games/Nintendo-Switch-download-software/Desta-The-Memories-Between-2296468.html" className={`${cssClass.link} ${cssClass.nintendo}`}>
        <GatsbyImage image={data.nintendoLogo.childImageSharp.gatsbyImageData} alt="Nintendo Switch link" />
      </a>
      <p className={cssClass.smallLink}><span><a href="http://www.netflix.com/game/81551216">Netflix subscription</a> required for mobile</span></p>
    </>
  );

  return (
    <div className={cssClass.top}>
      <div className={cssClass.mobileOnly}>
        <div className={cssClass.heroMobile}>
          <GatsbyImage image={data.heroMobile.childImageSharp.gatsbyImageData} alt="Desta Key Art" />
        </div>
        <div className={cssClass.mobileLinks}>
          {gameLinks}
        </div>
      </div>
      <div className={cssClass.fauxSpacing} />
      <div className={cssClass.video}>
        <div className={cssClass.videoInner}>
          <Video video={data.trailer} poster={data.trailerPoster} text="Trailer" />
        </div>
      </div>
      <div className={cssClass.description}>
        <div className={cssClass.descriptionInner}>
          <h2>Find the right words to say in Desta: The Memories Between, a cosy team-based tactics adventure. Encounter old friends, unlock new abilities, land trick-shots to resolve memories, all while falling deeper into a mysterious world.</h2>
          {/* <p>Desta: The Memories Between offers the depth of skill and mechanics that can be found in turn-based and roguelike games. Inspired by the likes of Hades, Into the Breach and Inception, Desta is the deepest, most ambitious and most replayable title from ustwo games yet, but with all the heart and approachability that the London studio is known for.</p> */}
          <p>Out Now on Netflix Games, Steam and Nintendo Switch</p>
        </div>
      </div>
      <div className={cssClass.video2}>
        <div className={cssClass.videoInner}>
          <Video video={data.gameplay} poster={data.gameplayPoster} text="Gameplay trailer" />
        </div>
      </div>
      <div className={cssClass.gallery}>
        <Gallery />
      </div>
      <div className={cssClass.pressKit}>
        <a href="https://go.ustwo.games/DestaMediaKit">
          Download Press Kit
        </a>
      </div>
      <div className={cssClass.sticky}>
        <div className={cssClass.background} style={bgImageBgStyles}>
          <GatsbyImage image={data.heroBg.childImageSharp.gatsbyImageData} alt="" />
        </div>
        <div className={cssClass.rocksFar} style={rocksFarStyles}>
          <GatsbyImage image={data.rocksFar.childImageSharp.gatsbyImageData} alt="" />
        </div>
        <div className={cssClass.rocksNear} style={rocksNearStyles}>
          <GatsbyImage image={data.rocksNear.childImageSharp.gatsbyImageData} alt="" />
        </div>
        <div className={cssClass.foreground} style={fgImageBgStyles}>
          {isMobile ? <GatsbyImage image={data.heroFg.childImageSharp.gatsbyImageData} alt="" /> : <GatsbyImage image={data.heroFg.childImageSharp.gatsbyImageData} alt="" />}
        </div>
        <div className={cssClass.glows} style={fgImageBgStyles}>
          <GatsbyImage image={data.glows.childImageSharp.gatsbyImageData} alt="" />
        </div>
        <div className={cssClass.textContent}>
          <h1 style={h1Styles} className={cssClass.logo}><GatsbyImage image={data.destaLogo.childImageSharp.gatsbyImageData} alt="Desta: The memories between" />Desta: The memories between</h1>
        </div>
        <div className={cssClass.links} style={linkStyles}>
          {gameLinks}
        </div>
      </div>
    </div>
  );
};

export default Top;
