import React, { useState, createRef } from "react";
import classnames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import * as cssClass from "./video.module.scss";

const Video = ({ video, poster, text, isMobile }) => {
  const videoRef = createRef();
  const [videoPlaying, setVideoPlaying] = useState(false);


  const handleClick = (e) => {
    e.preventDefault();
    setVideoPlaying(true);
    videoRef.current.play();
  }

  const handlePause = (e) => {
    e.preventDefault();
    setVideoPlaying(false);
    videoRef.current.pause();
  }

  const overlayClasses = classnames(cssClass.overlay, {
    [cssClass.playing]: videoPlaying
  });

  return (
    <div className={cssClass.video}>
      <video
        src={video.publicURL}
        playsInline
        onClick={handlePause}
        autoPlay={videoPlaying}
        ref={videoRef}
        preload={isMobile ? "none" : "auto"}
        controls
        type="video/mp4"
      >
        <track kind="captions" />
      </video>
      <button 
        className={overlayClasses}
        onClick={handleClick}
        onKeyPress={handleClick}
        tabIndex="0"
        aria-label="Play video"
      >
        {poster && <GatsbyImage image={poster.childImageSharp.gatsbyImageData} alt="" className={cssClass.overlayImage} />}
        <div className={cssClass.playButton}>
          <div className={cssClass.circle} />
          <div className={cssClass.label}>{text || "Play"}</div>
        </div>
      </button>
    </div>
  );
}

export default Video;
