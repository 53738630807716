import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Slider from "react-slick";
import { GatsbyImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/slider.css";
import * as cssClass from "./gallery.module.scss";

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "gallery/screenshot1.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      image2: file(relativePath: { eq: "gallery/screenshot2.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      image3: file(relativePath: { eq: "gallery/screenshot3.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      image4: file(relativePath: { eq: "gallery/screenshot4.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      image5: file(relativePath: { eq: "gallery/screenshot5.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      image6: file(relativePath: { eq: "gallery/screenshot6.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      image7: file(relativePath: { eq: "gallery/screenshot7.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `);
  const images = [
    data.image1, 
    data.image2,
    data.image3,
    data.image4,
    data.image5,
    data.image6,
    data.image7,
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '300px',
    className: cssClass.carousel,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          centerPadding: '200px'
        }
      },
      {
        breakpoint: 1280,
        settings: {
          centerPadding: '100px'
        }
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '50px'
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '20px'
        }
      }
    ]
  };

  return (
    <div className={cssClass.gallery}>
      <Slider {...settings}>
        {images.map(image => (
          <div className={cssClass.slide} key={image.id}>
            <GatsbyImage image={image.childImageSharp.gatsbyImageData} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Gallery;
