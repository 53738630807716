// extracted by mini-css-extract-plugin
export var background = "top-module--background--wVnUO";
export var content = "top-module--content--RCg4x";
export var description = "top-module--description--bTcLy";
export var descriptionInner = "top-module--descriptionInner--h-S8k";
export var fauxSpacing = "top-module--fauxSpacing--0o-hE";
export var foreground = "top-module--foreground--6cocq";
export var gallery = "top-module--gallery--Qf1AE";
export var glows = "top-module--glows--Zxc5f";
export var heroMobile = "top-module--heroMobile--2EZeh";
export var link = "top-module--link--WUm6F";
export var links = "top-module--links--kD03R";
export var logo = "top-module--logo--HjiNy";
export var mobileLinks = "top-module--mobileLinks--cDuSi";
export var mobileOnly = "top-module--mobileOnly--3Ps6G";
export var nintendo = "top-module--nintendo--IPz2Q";
export var playstation = "top-module--playstation--EmX-n";
export var pressKit = "top-module--pressKit--11saV";
export var rocksFar = "top-module--rocksFar--tN23H";
export var rocksNear = "top-module--rocksNear--48sLt";
export var screen = "top-module--screen--18ZPO";
export var smallLink = "top-module--smallLink--CX07F";
export var sticky = "top-module--sticky--GCk9h";
export var taglineWrapper = "top-module--taglineWrapper--5EHwv";
export var textContent = "top-module--textContent--NkARF";
export var top = "top-module--top--LvPHy";
export var video = "top-module--video--zQ-kD";
export var video2 = "top-module--video2--PrAgk";
export var videoInner = "top-module--videoInner--7VfnL";
export var xbox = "top-module--xbox--JP+2F";