import React from "react";
// import classnames from "classnames";
// import * as cssClass from "./section.module.scss";
//, cssClass[className]

const Section = ({ children, id }) => {
  return (
    <section id={id}>
      {children}
    </section>
  );
};

export default Section;
