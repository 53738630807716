import React from "react";
import Footer from "../components/footer";
import "../styles/index.scss";
import * as cssClass from "./layout.module.scss";

const Layout = ({ children, className }) => (
  <div className={`${cssClass.wrapper} ${className}`}>
    {children}
    <Footer />
  </div>
);

export default Layout;
